/* You can add global styles to this file, and also import other style files */
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
/* Importing Bootstrap SCSS file. */
@import 'node_modules/bootstrap/scss/bootstrap';
@import "@angular/material/prebuilt-themes/indigo-pink.css";


.categoryCarousel2 .owl-carousel.owl-drag .owl-item {
    max-width: 25% !important;
    cursor: pointer;
}

.categoryCarousel2 .owl-carousel.owl-drag .owl-item .ci01Thumb {
    background: #fff;
    border-radius: 10px;
    height: 155px !important;
}

.categoryCarousel2 .owl-carousel.owl-drag .owl-item .ci01Thumb img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}


.categoryCarousel3 .owl-carousel.owl-drag .owl-item {
    max-width: 25% !important;
    background: #fff;
    border: 1px px solid var(--tp-border-3);
    border-radius: 0;
    overflow: hidden;
}

.categoryCarousel3 .owl-carousel.owl-drag .owl-item .ci01Thumb {
    background: #fff;
    border-radius: 10px;
    height: 155px !important;
}

.categoryCarousel3 .owl-carousel.owl-drag .owl-item .ci01Thumb img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

a{
    text-decoration: none;
}
.home_slider_image{
    width: 100%;
    max-height: 400px;
}

